import axiosWarSmartSolution from '@/services/warSmartSolutionService.js';
import { isSamePagination } from '@/utils/pagination';
import UtilsDate from '@/utils/UtilsDate';
import { cloneDeep, uniqueId } from 'lodash';

function defaultPagination() {
  return {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
  };
}
export default {
  namespaced: true,

  state: {
    paginationColab: {
      itemsPerPage: 50,
      page: 1,
      sortBy: [],
    },
    pagination: {
      itemsPerPage: 50,
      page: 1,
      sortBy: [],
    },
    paginationNocAg: {
      itemsPerPage: 50,
      page: 1,
      sortBy: [],
    },

    scheduleReport: false,
    historyImportBlob: '',
    nociveAgentBlob: '',
    filterApplied: false,
    // collaboratorNocAgModal: [],
    // totNocivAgnentsModal: [],
    categoriesList: [],
    loadingTable: false,
    loadingTableAgNocivo: false,
    loadingEnsejador: false,
    loadingAposEspecial: false,
    loadingExposedCollaborator: false,
    loadingNociveAgentsModal: false,
    loadingRetirementRisks: false,
    loadingRiscoExposi: false,
    applyBtnStatus: true,
    competencyInSelected: '',
    companySelected: [],
    categorySelected: [],
    previousEmpresaSelecionada: [],
    previousCategoriaSelecionada: [],
    countNociveAgentsFilter: { cnpjs: [], competency: '', categories: [] },
    countNociveAgents: [],
    countNociveAgentsForFilter: [],
    countRetirementAliquot: [],
    collaborators: [],
    totalCollaborators: 0,
    totalNociveAgents: 0,
    collaboratorsSize: 0,
    nociveAgentsSize: 0,
    nociveAgents: [],
    competencias: [],
    exposedCollaborators: {},
    retirementRisks: {},
    retirementRisksFilter: [],
    isFromFilterExposed: false,
    isFromFilterSpecial: false,
    textForFilter: [],
    historyModalBool: false,
    nameCpfMatriculation: '',
    nociveAgentCodeDesc: '',
    historyImport: [],
    lastHistory: '',
    loadingHistory: false,
    maxExportLimit: 250,
  },
  getters: {
    getCompetencias(state) {
      const mappedCompetencias = state.competencias.map((compentencia) => ({
        value: compentencia,
        label: UtilsDate.format(compentencia, 'MM/YYYY'),
      }));
      mappedCompetencias.forEach((competencia, index) => {
        const current = UtilsDate.format(competencia?.value, 'YYYY');
        const next = UtilsDate.format(mappedCompetencias[index + 1]?.value, 'YYYY');
        if (index === 0) mappedCompetencias.splice(0, 0, { label: current, value: current, disabled: true });
        if (next && current && next !== current) {
          mappedCompetencias.splice(index + 1, 0, { label: next, value: next, disabled: true });
        }
      });
      return mappedCompetencias;
    },
    getHistoryImport(state) {
      return state.historyImport;
    },
    // getCollaboratorNocAgModal(state) {
    //   return state.collaboratorNocAgModal;
    // },
    // getTotNocivAgnentsModal(state) {
    //   return state.totNocivAgnentsModal;
    // },
    getLastHistory(state) {
      return state.lastHistory;
    },
    getScheduleReport(state) {
      return state.scheduleReport;
    },
    getBlobHistoryImport(state) {
      return state.historyImportBlob;
    },
    getNociveAgentBlob(state) {
      return state.nociveAgentBlob;
    },
    getHistoryModalBool(state) {
      return state.historyModalBool;
    },
    getFilterApply(state) {
      return state.filterApplied;
    },
    getCountNociveAgentFilter(state) {
      return state.countNociveAgentsFilter;
    },
    getTextForFilter(state) {
      return state.textForFilter;
    },
    getActiveExposed(state) {
      return state.isFromFilterExposed;
    },
    getActiveSpecialRetirement(state) {
      return state.isFromFilterSpecial;
    },
    getEmpresaSelecionada(state) {
      return state.companySelected;
    },
    geCategoriaSelecionada(state) {
      return state.categorySelected;
    },
    getLoadingTableAgNocivo(state) {
      return state.loadingTableAgNocivo;
    },
    getLoadingTable(state) {
      return state.loadingTable;
    },
    getTotalCollaborators(state) {
      return state.totalCollaborators;
    },
    getTotalNociveAgents(state) {
      return state.totalNociveAgents;
    },
    getCountNociveAgents(state) {
      return state.countNociveAgents;
    },
    getCountNociveAgentsForFilter(state) {
      return state.countNociveAgentsForFilter;
    },
    getCountRetirementAliquot(state) {
      return state.countRetirementAliquot;
    },
    getCountExposedCollaborators(state) {
      return state.exposedCollaborators;
    },
    getRetirementRisks(state) {
      return state.retirementRisks;
    },
    getRetirementRisksFilter(state) {
      if (state.retirementRisksFilter && !state.retirementRisksFilter.length) {
        return [];
      }
      return state.retirementRisksFilter;
    },
    getCollaborators(state) {
      return state.collaborators;
    },
    getNociveAgents(state) {
      return state.nociveAgents;
    },
    categoriesList(state) {
      return state.categoriesList.map((item) => {
        return item.subtitle
          ? { id: item.subtitle, descricao: item.subtitle, isGroup: true }
          : { ...item, codigo: item.codigo };
      });
    },
    getCompetencyInSelected(state) {
      return state.competencyInSelected;
    },
    getApplyBtnStatus(state) {
      return state.applyBtnStatus;
    },
    getPaginationColab(state) {
      return state.paginationColab;
    },
    getPaginationNocAg(state) {
      return state.paginationNocAg;
    },

    getLoadingGraphs(state) {
      return state.loadingEnsejador || state.loadingExposedCollaborator;
    },

    getAllDataLoaded(state) {
      let loaded = false;
      if (
        state.loadingEnsejador ||
        state.loadingExposedCollaborator ||
        state.loadingRetirementRisks ||
        state.loadingTable
      ) {
        loaded = true;
      }
      return loaded;
    },
  },

  actions: {
    async init({ dispatch }) {
      await dispatch('getCurrentMonth');
      await dispatch('getCategories');
    },
    async getCurrentMonth({ commit }) {
      let { data } = await axiosWarSmartSolution.wss.availableCompetencies();
      commit('SET_COMPETENCY', data);
    },
    searchCollaborator({ commit, dispatch, state }, value) {
      commit('SET_LOADINGTABLE', true);
      let filter = cloneDeep(state.countNociveAgentsFilter);
      if (value != '' && value != null) {
        filter.nameCpfMatriculation = value;
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      } else {
        delete filter.nameCpfMatriculation;
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      }

      dispatch('setPaginationColab', defaultPagination());
    },
    searchNociveAgent({ commit, dispatch, state }, value) {
      let filter = cloneDeep(state.countNociveAgentsFilter);
      if (value != '' && value != null) {
        filter.nociveAgentCodeDesc = value;
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      } else {
        delete filter.nociveAgentCodeDesc;
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      }

      dispatch('setPaginationNocAg', defaultPagination());
    },

    async exportCollaborators({ commit, state }) {
      let filter = state.countNociveAgentsFilter;

      let serialized = {
        params: {
          ...filter,
          retirementAliquot: filter.retirementAliquot?.map((item) => ({ year: item.key, aliquot: item.aliquotKey })),
        },
      };

      // let serialized = { params: state.countNociveAgentsFilter };

      let response = await axiosWarSmartSolution.wss.collaboratorRetirementRisksExport(serialized);
      commit('SET_RESULT_SCHEDULE_REPORT', response.status === 202);
    },
    async exportNociveAgents({ commit, state }) {
      let serialized = { params: state.countNociveAgentsFilter };
      let response = await axiosWarSmartSolution.wss.nociveAgentsExport(serialized);
      commit('SET_RESULT_SCHEDULE_REPORT', response.status === 202);
    },
    async exportHistoryImport({ commit, state }) {
      let serialized = { params: state.countNociveAgentsFilter };
      let response = await axiosWarSmartSolution.wss.historyImportExport(serialized);

      let blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      commit('SET_HISTORYIMPORTBLOB', blob);
    },
    async historyImport({ commit, state }) {
      commit('SET_LOADINGHISTORY', true);

      let serialized = { params: state.countNociveAgentsFilter };

      let response = await axiosWarSmartSolution.wss.historyImport(serialized);
      let last = response.data[response.data.length - 1];
      let splitted = '';
      if (last != undefined) {
        splitted = last.replace('-', 'às');
        commit('SET_LOADINGHISTORY', false);
        commit('SET_LASTHISTORY', splitted);
        commit('SET_HISTORYIMPORT', response.data);
      }
    },

    cleanFilterTags({ commit, state }) {
      let filter = cloneDeep(state.countNociveAgentsFilter);
      delete filter.retirementAliquot;
      delete filter.nociveAgent;
      delete filter.specialRetirement;
      delete filter.onlyExposedRisks;
      commit('SET_FILTERAPPLY', false);

      commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
    },
    changeNociveAgent({ commit, state }, payload) {
      let filter = cloneDeep(state.countNociveAgentsFilter);

      filter.nociveAgent = Array.isArray(payload) ? payload : [payload];
      commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
    },
    changeSpecialRetirement({ commit, state }, payload) {
      let filter = cloneDeep(state.countNociveAgentsFilter);

      filter.specialRetirement = payload;
      commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
    },
    changeRetirementAliquot({ commit, state }, payload) {
      let filter = cloneDeep(state.countNociveAgentsFilter);
      filter.retirementAliquot = payload;
      commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
    },
    changeOnlyExposedRisk({ commit, state }, payload) {
      let filter = cloneDeep(state.countNociveAgentsFilter);

      filter.onlyExposedRisks = payload;
      commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
    },
    toggleHistoryModal({ commit }, bool) {
      commit('SET_HISTORYMODALBOOL', bool);
    },

    specificFilter({ commit, dispatch }) {
      commit('SET_COLLABORATORS', []);
      commit('SET_FILTERAPPLY', true);
      dispatch('collaboratorRetirementRisks');
    },

    applyFilter({ commit, state, dispatch }, isClearing) {
      dispatch('changeApplyBtnStatus', !state.applyBtn);
      dispatch('clearResults');

      let codeSelecteds = [];

      let companiesForFilter = [];
      let categoriesForFilter = [];

      if (state.companySelected.length > 0) {
        companiesForFilter = state.companySelected.map((item) => item.value);
      }

      if (state.categorySelected.length > 0) {
        codeSelecteds = state.categorySelected
          .filter((item) => {
            return !item.subtitle;
          })
          .map((item) => {
            return item.codigo;
          });
        categoriesForFilter = codeSelecteds;
      }

      let competencyForFilter = '';
      if (state.competencyInSelected != '') {
        competencyForFilter = state.competencyInSelected;
      }

      let filter = {
        ...competencyForFilter,
        cnpjs: companiesForFilter,
        categories: categoriesForFilter,
      };

      commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      commit('SET_LOADINGEXPOSEDCOLABORATOR', true);
      commit('SET_LOADINGRETIREMENTRISKS', true);
      commit('SET_LOADING_TABLE_AGNOCIVO', true);
      commit('SET_LOADINGENSEJADOR', true);

      if (isClearing) {
        dispatch('cleanFilters');
        return;
      }

      // dispatch('getCategories');
      dispatch('historyImport');
      dispatch('collaboratorRetirementRisks');
      dispatch('nociveAgents');
      dispatch('chartsCounts');
    },

    setIsFromFilterExposed({ commit, state, dispatch }, bool) {
      if (bool === false) {
        commit('SET_ISFROMFILTERSPECIAL', bool);
      } else if (bool === true) {
        commit('SET_ISFROMFILTERSPECIAL', !bool);
      }
      let filter = cloneDeep(state.countNociveAgentsFilter);

      delete filter.retirementYears;
      delete filter.retirementAliquot;
      delete filter.nociveAgent;
      delete filter.specialRetirement;

      if (bool === true) {
        filter.onlyExposedRisks = true;
      } else {
        delete filter.onlyExposedRisks;
      }

      commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      dispatch('exposedRisksFromGraph');
      commit('SET_ISFROMFILTEREXPOSED', bool);
    },
    setIsFromFilterSpecial({ commit, state, dispatch }, bool) {
      if (bool === false) {
        commit('SET_ISFROMFILTEREXPOSED', bool);
      } else if (bool === true) {
        commit('SET_ISFROMFILTEREXPOSED', !bool);
      }
      let filter = cloneDeep(state.countNociveAgentsFilter);

      delete filter.retirementYears;
      delete filter.retirementAliquot;
      delete filter.nociveAgent;
      delete filter.onlyExposedRisks;
      if (bool === true) {
        filter.specialRetirement = true;
      } else {
        delete filter.specialRetirement;
      }

      commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      dispatch('specialRetirementFromGraph');
      commit('SET_ISFROMFILTERSPECIAL', bool);
    },
    async chartsCounts({ state, commit, dispatch }) {
      if (!state.competencyInSelected && state.companySelected?.length < 1) {
        return;
      }
      try {
        let response = await axiosWarSmartSolution.wss.chartsCounts(state.countNociveAgentsFilter);

        // countExposedColaborators
        if (response.data.exposedCollaborators != undefined) {
          commit('SET_LOADINGEXPOSEDCOLABORATOR', false);
        }
        commit('SET_EXPOSEDCOLLABORATORS', response.data.exposedCollaborators);

        // countRetirementRisks
        if (response.data.retirementRisks != undefined) {
          commit('SET_LOADINGRETIREMENTRISKS', false);
        }
        commit('SET_RETIREMENTRISKS', response.data.retirementRisks);

        let retirementOrNot = [
          { name: `Com Risco (${response.data.retirementRisks.specialRetirements})`, key: 'true' },
          {
            name: `Sem Risco (${response.data.retirementRisks.total - response.data.retirementRisks.specialRetirements})`,
            key: 'false',
          },
        ];
        commit('SET_RETIREMENTRISKSFILTER', retirementOrNot);

        // countNociveAgents
        let nocivos = [];
        // commit('SET_COUNTAGNOCIVOS', nocivos);

        if (Object.keys(response.data).length > 0) {
          nocivos[0] = response.data.nociveAgents.nothing ? response.data.nociveAgents.nothing : 0;
          nocivos[1] = response.data.nociveAgents.chemical ? response.data.nociveAgents.chemical : 0;
          nocivos[2] = response.data.nociveAgents.physical ? response.data.nociveAgents.physical : 0;
          nocivos[3] = response.data.nociveAgents.biological ? response.data.nociveAgents.biological : 0;
          nocivos[4] = response.data.nociveAgents.others ? response.data.nociveAgents.others : 0;
          nocivos[5] = response.data.nociveAgents.all ? response.data.nociveAgents.all : 0;

          commit('SET_LOADING_TABLE_AGNOCIVO', false);
          dispatch('changeApplyBtnStatus', true);
          commit('SET_COUNTAGNOCIVOS', nocivos);
        }

        if (nocivos.length < 1) {
          commit('SET_LOADING_TABLE_AGNOCIVO', false);
        }

        // countRetirementAliquot
        commit('SET_LOADINGENSEJADOR', true);
        let retirementAliquot = [];
        commit('SET_COUNTRETIREMENTALIQUOT', retirementAliquot);

        let textsForFilter = [
          { key: '25', text: '', aliquotKey: '' },
          { key: '20', text: '', aliquotKey: '' },
          { key: '15', text: '', aliquotKey: '' },
        ];
        if (Object.keys(response.data).length != 0) {
          retirementAliquot[0] =
            response.data.retirementYearsAliquot['nothing'] != undefined
              ? response.data.retirementYearsAliquot['nothing']
              : 0;
          retirementAliquot[1] =
            response.data.retirementYearsAliquot['25y-6%'] != undefined
              ? response.data.retirementYearsAliquot['25y-6%']
              : 0;
          textsForFilter[0].text = `25 anos - Aliquota de 6%(${response.data.retirementYearsAliquot['25y-6%'] != undefined ? response.data.retirementYearsAliquot['25y-6%'] : 0})`;
          textsForFilter[0].aliquotKey = '6';
          retirementAliquot[2] =
            response.data.retirementYearsAliquot['20y-9%'] != undefined
              ? response.data.retirementYearsAliquot['20y-9%']
              : 0;
          textsForFilter[1].text = `20 anos - Aliquota de 9%(${response.data.retirementYearsAliquot['20y-9%'] != undefined ? response.data.retirementYearsAliquot['20y-9%'] : 0})`;
          textsForFilter[1].aliquotKey = '9';
          retirementAliquot[3] =
            response.data.retirementYearsAliquot['15y-12%'] != undefined
              ? response.data.retirementYearsAliquotata['15y-12%']
              : 0;
          textsForFilter[2].text = `15 anos - Aliquota de 12%(${response.data.retirementYearsAliquot['15y-12%'] != undefined ? response.data.retirementYearsAliquotata['15y-12%'] : 0})`;
          textsForFilter[2].aliquotKey = '12';
          dispatch('changeApplyBtnStatus', true);
          commit('SET_TEXTSFORFILTER', textsForFilter);
          commit('SET_COUNTRETIREMENTALIQUOT', retirementAliquot);
        } else {
          commit('SET_LOADINGENSEJADOR', false);
        }

        if (retirementAliquot.length > 0) {
          commit('SET_LOADINGENSEJADOR', false);
        } else if (response.error) {
          commit('SET_LOADINGENSEJADOR', false);
        }
      } catch (e) {
        let errorMessage = e.code === 'ERR_BAD_REQUEST' ? 'User have no establishment selected.' : e.message;
        console.error('Error fetching charts count: ', errorMessage);
      }
    },
    // async fetchCollaboratorForNociveAgentsModal({ state, commit }, payload) {
    //   if (!state.competencyInSelected && state.companySelected.length < 1) {
    //     commit('SET_LOADING_NOCIVEAGENTS_MODAL', false);
    //     return;
    //   }

    //   commit('SET_COLLABORATORS', []);
    //   let filter = cloneDeep(payload);
    //   let pagination = 'page=0&size=2000';
    //   let serialized = {
    //     params: {
    //       ...filter,
    //       retirementAliquot: filter.retirementAliquot?.map((item) => ({ year: item.key, aliquot: item.aliquotKey })),
    //     },
    //   };
    //   const response = await axiosWarSmartSolution.wss.collaboratorRetirementRisks(serialized, pagination);
    //   let collaborators = response.data.content;
    //   commit('SET_TOTAL_MODALNOCAGENT', response.data.numberOfElements ? response.data.numberOfElements : 0);
    //   commit('SET_COLLABSNOCIVAGENT', collaborators);
    //   commit('SET_LOADING_NOCIVEAGENTS_MODAL', false);
    // },

    async collaboratorRetirementRisks({ state, commit, rootState }) {
      if (!state.competencyInSelected && state.companySelected.length < 1) {
        commit('SET_LOADINGTABLE', false);
        return;
      }
      commit('SET_LOADINGTABLE', true);

      let collaborator = [];
      commit('SET_COLLABORATORS', []);
      let filter = cloneDeep(state.countNociveAgentsFilter);

      let pagination = `page=${state.paginationColab.page - 1}&size=${state.paginationColab.itemsPerPage}`;
      if (state.paginationColab.sortBy.length > 0) {
        pagination = `${pagination}&sort=${state.paginationColab.sortBy[0]},${state.paginationColab.sortDesc[0] === true ? 'desc' : state.paginationColab.sortDesc[0] === false ? 'asc' : ''}`;
      }
      let serialized = {
        params: {
          ...filter,
          retirementAliquot: filter.retirementAliquot?.map((item) => ({ year: item.key, aliquot: item.aliquotKey })),
        },
      };
      try {
        const response = await axiosWarSmartSolution.wss.collaboratorRetirementRisks(serialized, pagination);
        let collaborators = response.data.content;

        commit('SET_TOTAL_COLLABORATORS', response.data.totalElements ? response.data.totalElements : 0);
        collaborators.forEach((item) => {
          if (item.retirementAliquot) {
            item.retirementAliquot = item.retirementYears + ' anos / ' + item.retirementAliquot + '%';
          } else {
            item.retirementAliquot = 'Ausente';
          }
          item.cpf = item.cpf.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, '$1.$2.$3-$4');
          item.cpfMask = item.cpf;
          item.socialName = findSocialName(item, rootState.user.userEstablishments);
          item.nameAndMatriculation = `${item.name} ${item.matriculation}`;
        });
        commit('SET_COLLABORATORS', collaborators);
      } catch (e) {
        let errorMessage = e.code === 'ERR_BAD_REQUEST' ? 'User have no establishment selected.' : e.message;
        console.error('Error fetching collaborators: ', errorMessage);
      } finally {
        commit('SET_LOADINGTABLE', false);
        commit('SET_LOADING_NOCIVEAGENTS_MODAL', false);
        commit('SET_COLLABORATORS_SIZE', collaborator.length);
      }
    },

    clearResults({ commit }) {
      commit('SET_COLLABORATORS', []);
      commit('SET_NOCIVE_AGENTS', []);
      commit('SET_COUNTAGNOCIVOS', []);
      commit('SET_RETIREMENTRISKSFILTER', []);
      commit('SET_EXPOSEDCOLLABORATORS', []);
      commit('SET_RETIREMENTRISKS', []);
      commit('SET_COUNTRETIREMENTALIQUOT', []);
      commit('SET_COUNTNOCIVEAGENTSFILTER', {});
      commit('SET_TOTAL_COLLABORATORS', 0);
      commit('SET_TOTAL_NOCIVE_AGENTS', 0);
    },

    async nociveAgents({ state, commit, rootState }) {
      if (!state.competencyInSelected && state.companySelected.length < 1) {
        commit('SET_LOADING_TABLE_AGNOCIVO', false);
        return;
      }

      let nociveAgents = [];
      commit('SET_LOADING_TABLE_AGNOCIVO', true);
      commit('SET_NOCIVE_AGENTS', nociveAgents);

      let pagination = `page=${state.paginationNocAg.page - 1}&size=${state.paginationNocAg.itemsPerPage}`;

      if (state.paginationNocAg.sortBy.length > 0) {
        pagination = `${pagination}`;
        pagination += `&sort=${state.paginationNocAg.sortBy[0]}`;
        pagination += `,${state.paginationNocAg.sortDesc[0] ? 'desc' : 'asc'}`;
      }
      try {
        let serialized = { params: state.countNociveAgentsFilter };
        let response = await axiosWarSmartSolution.wss.nociveAgents(serialized, pagination);
        let responseForFilter = await axiosWarSmartSolution.wss.countCollaboratorsByAgent(serialized);

        nociveAgents = response.data.content;

        nociveAgents.forEach(async (item) => {
          if (item.registeredLimit && item.limitType) {
            item.registeredLimit = item.limitType + ': ' + item.registeredLimit;
          }

          item.socialName = await findSocialName(item, rootState.user.userEstablishments);
          item.id = uniqueId();
        });
        let objectForFilter = [];

        Object.entries(responseForFilter.data).forEach((item) => {
          objectForFilter.push({ name: `${item[0]}(${item[1]})`, code: item[0] });
        });

        commit('SET_NOCIVE_AGENTS', nociveAgents);
        commit('SET_COUNT_NOCIVE_AGENTS_FOR_FILTER', objectForFilter);
        commit('SET_TOTAL_NOCIVE_AGENTS', response.data.totalElements ? response.data.totalElements : 0);
      } catch (e) {
        let errorMessage = e.code === 'ERR_BAD_REQUEST' ? 'User have no establishment selected.' : e.message;
        console.error('Error fetching nocive agents: ', errorMessage);
      } finally {
        commit('SET_LOADING_TABLE_AGNOCIVO', false);
        commit('SET_NOCIVE_AGENTS_SIZE', nociveAgents.length);
      }
    },
    async specialRetirementFromGraph({ state, commit }) {
      if (!state.competencyInSelected && state.companySelected.length < 1) {
        return;
      }

      commit('SET_LOADINGTABLE', true);
      commit('SET_COLLABORATORS', []);

      let pagination = `page=${state.paginationNocAg.page - 1}&size=${state.paginationNocAg.itemsPerPage}`;
      let serialized = { params: cloneDeep(state.countNociveAgentsFilter) };
      let response = await axiosWarSmartSolution.wss.collaboratorRetirementRisks(serialized, pagination);
      const collaborator = response.data.content;

      commit('SET_TOTAL_COLLABORATORS', response.data.totalElements ? response.data.totalElements : 0);
      commit('SET_COLLABORATORS', collaborator);
      commit('SET_LOADINGTABLE', false);
    },
    async exposedRisksFromGraph({ state, commit }) {
      if (!state.competencyInSelected && state.companySelected.length < 1) {
        return;
      }
      commit('SET_LOADINGTABLE', true);

      let collaborator = [];

      commit('SET_COLLABORATORS', collaborator);
      let filter = cloneDeep(state.countNociveAgentsFilter);

      let pagination = `page=${state.paginationNocAg.page - 1}&size=${state.paginationNocAg.itemsPerPage}`;
      let serialized = { params: filter };

      let response = await axiosWarSmartSolution.wss.collaboratorRetirementRisks(serialized, pagination);

      collaborator = response.data.content;

      commit('SET_TOTAL_COLLABORATORS', response.data.totalElements ? response.data.totalElements : 0);
      commit('SET_COLLABORATORS', collaborator);
      commit('SET_LOADINGTABLE', false);
    },

    async changeCompany({ commit, state, dispatch }, payload) {
      if (state.competencyInSelected) {
        dispatch('changeApplyBtnStatus', false);
      }

      commit('SET_PREVIOUSEMPRESASELECIONADA', payload);
      commit('SET_EMPRESASELECIONADA', payload);
    },
    async changeCompetencia({ commit, dispatch }, payload) {
      dispatch('changeApplyBtnStatus', false);
      commit('SET_COMPETENCYINSELECTED', payload);
    },

    async changeCategory({ commit, state }, payload) {
      const categoriesAdded = payload.filter((value) => !state.previousCategoriaSelecionada.includes(value), 0);

      const categoriesRemoved = state.previousCategoriaSelecionada.filter((value) => !payload.includes(value), 0);

      let fullAddition = false;
      for (let i = 0; i < categoriesAdded.length; i++) {
        const c = categoriesAdded[i];
        if (c.subtitle) {
          fullAddition = true;
          break;
        }
      }
      let categoriesSelecteds = [];
      if (categoriesAdded.length > 0 && fullAddition) {
        const lastValueEvent = payload[payload.length - 1];

        if (lastValueEvent.subtitle) {
          state.previousCategoriaSelecionada.forEach((c) => {
            if (
              (c.grupo && c.grupo != lastValueEvent.subtitle) ||
              (c.subtitle && c.subtitle != lastValueEvent.subtitle)
            ) {
              categoriesSelecteds.push(c);
            }
          });

          state.categoriesList.filter((item) => {
            if (item.grupo == lastValueEvent.subtitle || item.subtitle == lastValueEvent.subtitle) {
              if (item.grupo || item.subtitle) {
                categoriesSelecteds.push(item);
              }
            }
          });
        } else {
          categoriesSelecteds.push(payload);
        }

        commit('SET_PREVIOUSCATEGORIASELECIONADA', categoriesSelecteds);
        commit('SET_CATEGORIASELECIONADA', categoriesSelecteds);
      } else {
        commit('SET_PREVIOUSCATEGORIASELECIONADA', payload);
        commit('SET_CATEGORIASELECIONADA', payload);
      }

      if (categoriesRemoved.length > 0 && categoriesRemoved[0].subtitle) {
        payload = payload.filter((item) => item.group !== categoriesRemoved[0].subtitle);

        let selectedOnes = state.previousCategoriaSelecionada.filter(
          (item) => item.grupo !== categoriesRemoved[0].subtitle,
        );

        commit('SET_PREVIOUSCATEGORIASELECIONADA', selectedOnes);
        commit('SET_CATEGORIASELECIONADA', selectedOnes);
      }
    },
    async changeApplyBtnStatus({ commit }, payload) {
      commit('SET_APPLYBTNSTATUS', payload);
    },

    cleanFilters({ commit, dispatch }) {
      commit('SET_COMPETENCYINSELECTED', '');
      commit('SET_EMPRESASELECIONADA', []);
      commit('SET_CATEGORIASELECIONADA', []);
      commit('SET_PREVIOUSEMPRESASELECIONADA', []);
      commit('SET_PREVIOUSCATEGORIASELECIONADA', []);
      commit('SET_RETIREMENTRISKS', {});
      commit('SET_RETIREMENTRISKSFILTER', {});
      commit('SET_EXPOSEDCOLLABORATORS', {});
      commit('SET_COLLABORATORS', []);
      commit('SET_COUNTNOCIVEAGENTSFILTER', { cnpjs: [], competency: '', categories: [] });
      commit('SET_COUNTAGNOCIVOS', []);
      commit('SET_COUNTRETIREMENTALIQUOT', []);
      commit('SET_COLLABORATORS_SIZE', 0);
      commit('SET_NOCIVE_AGENTS', []);
      commit('SET_NOCIVE_AGENTS_SIZE', 0);
      commit('SET_TOTAL_COLLABORATORS', 0);
      commit('SET_TOTAL_NOCIVE_AGENTS', 0);
      commit('SET_ISFROMFILTEREXPOSED', false);
      commit('SET_ISFROMFILTERSPECIAL', false);
      commit('SET_LOADINGEXPOSEDCOLABORATOR', false);
      commit('SET_LOADINGRETIREMENTRISKS', false);
      commit('SET_LOADING_TABLE_AGNOCIVO', false);
      commit('SET_LOADINGENSEJADOR', false);

      dispatch('changeApplyBtnStatus', true);
    },
    async getCategories({ commit, state }) {
      let response = await axiosWarSmartSolution.wss.categories(state.countNociveAgentsFilter);
      let results = response.data;

      commit('SET_CATEGORIES', results);
    },
    setPaginationColab({ dispatch, commit, state }, payload) {
      let isSame = isSamePagination(state.paginationColab, payload);

      commit('SET_PAGINATIONCOLAB', payload);
      if (!isSame) {
        dispatch('collaboratorRetirementRisks');
      }
    },
    setPaginationNocAg({ dispatch, commit, state }, payload) {
      let isSame = isSamePagination(state.paginationNocAg, payload);

      commit('SET_PAGINATIONNOCAG', payload);
      if (!isSame) {
        dispatch('nociveAgents');
      }
    },
  },

  mutations: {
    SET_CATEGORIES(state, payload) {
      state.categoriesList = payload;
    },
    SET_APPLYBTNSTATUS(state, payload) {
      state.applyBtnStatus = payload;
    },
    SET_EMPRESASELECIONADA(state, payload) {
      state.companySelected = payload;
    },
    SET_CATEGORIASELECIONADA(state, payload) {
      state.categorySelected = payload;
    },
    SET_COMPETENCYINSELECTED(state, payload) {
      state.competencyInSelected = payload;
    },
    SET_PREVIOUSEMPRESASELECIONADA(state, payload) {
      state.previousEmpresaSelecionada = payload;
    },
    SET_PREVIOUSCATEGORIASELECIONADA(state, payload) {
      state.previousCategoriaSelecionada = payload;
    },
    SET_COUNTNOCIVEAGENTSFILTER(state, payload) {
      state.countNociveAgentsFilter = payload;
    },
    SET_LOADINGEXPOSEDCOLABORATOR(state, payload) {
      state.loadingExposedCollaborator = payload;
    },
    SET_LOADING_NOCIVEAGENTS_MODAL(state, payload) {
      state.loadingNociveAgentsModal = payload;
    },
    SET_COUNT_NOCIVE_AGENTS_FOR_FILTER(state, payload) {
      state.countNociveAgentsForFilter = payload;
    },
    SET_LOADINGTABLE(state, payload) {
      state.loadingTable = payload;
    },
    SET_LOADING_TABLE_AGNOCIVO(state, payload) {
      state.loadingTableAgNocivo = payload;
    },
    SET_LOADINGENSEJADOR(state, payload) {
      state.loadingEnsejador = payload;
    },
    SET_RESULT_SCHEDULE_REPORT(state, payload) {
      state.scheduleReport = payload;
    },
    SET_HISTORYIMPORTBLOB(state, payload) {
      state.historyImportBlob = payload;
    },
    SET_LOADINGRETIREMENTRISKS(state, payload) {
      state.loadingRetirementRisks = payload;
    },
    SET_ISFROMFILTEREXPOSED(state, payload) {
      state.isFromFilterExposed = payload;
    },
    SET_ISFROMFILTERSPECIAL(state, payload) {
      state.isFromFilterSpecial = payload;
    },
    SET_TEXTSFORFILTER(state, payload) {
      state.textForFilter = payload;
    },
    SET_COUNTAGNOCIVOS(state, payload) {
      state.countNociveAgents = payload;
    },
    SET_COUNTRETIREMENTALIQUOT(state, payload) {
      state.countRetirementAliquot = payload;
    },
    SET_COLLABORATORS(state, payload) {
      state.collaborators = payload;
    },
    SET_LOADINGHISTORY(state, payload) {
      state.loadingHistory = payload;
    },
    SET_TOTAL_COLLABORATORS(state, payload) {
      state.totalCollaborators = payload;
    },
    SET_TOTAL_NOCIVE_AGENTS(state, payload) {
      state.totalNociveAgents = payload;
    },
    SET_COLLABORATORS_SIZE(state, payload) {
      state.collaboratorsSize = payload;
    },
    SET_NOCIVE_AGENTS_SIZE(state, payload) {
      state.nociveAgentsSize = payload;
    },
    SET_NOCIVE_AGENTS(state, payload) {
      state.nociveAgents = payload;
    },
    SET_EXPOSEDCOLLABORATORS(state, payload) {
      state.exposedCollaborators = payload;
    },
    SET_RETIREMENTRISKS(state, payload) {
      state.retirementRisks = payload;
    },
    SET_RETIREMENTRISKSFILTER(state, payload) {
      state.retirementRisksFilter = payload;
    },
    SET_COMPETENCY(state, payload) {
      state.competencias = payload;
    },
    SET_HISTORYIMPORT(state, payload) {
      state.historyImport = payload;
    },
    SET_LASTHISTORY(state, payload) {
      state.lastHistory = payload;
    },
    SET_FILTERAPPLY(state, payload) {
      state.filterApplied = payload;
    },
    SET_PAGINATIONCOLAB(state, payload) {
      state.paginationColab = payload;
    },
    SET_PAGINATIONNOCAG(state, payload) {
      state.paginationNocAg = payload;
    },
    SET_HISTORYMODALBOOL(state, payload) {
      state.historyModalBool = payload;
    },
    // SET_TOTAL_MODALNOCAGENT(state, payload) {
    //   state.totNocivAgnentsModal = payload;
    // },
    // SET_COLLABSNOCIVAGENT(state, payload) {
    //   state.collaboratorNocAgModal = payload;
    // },
  },
};

function findSocialName(collaborator, userEstablishments) {
  let empresa = userEstablishments.find((item) => item.details && item.details.cnpj === collaborator.cnpj);
  return empresa ? empresa.details?.socialName : collaborator.trabalhador?.socialName;
}
